
























import Base from './input-thumbnail';
import {Component, Prop} from "vue-property-decorator";
import {IFileDefaultValue} from "@/contracts/i-file";
import {i18n} from "@/bootstraps/locale";
import router from "@/router";
import * as FileSaver from "file-saver";
import IconCamera from "@/components/Icons/IconCamera.vue";
import IconFile from "@/components/Icons/IconFile.vue";

@Component({
  components: {IconFile, IconCamera}
})
export default class extends Base {
  @Prop({ default: i18n.t("ここに画像をドラッグ or クリック") }) private placeholder!: string;
  @Prop({ default: 'camera' }) private iconType!: 'camera' | 'file';

  private remove() {
    this.$emit('input', {
      ...IFileDefaultValue,
      __destroy: true
    });
    this.onRemoved();
  }
}
